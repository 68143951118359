import React, { useEffect, useRef } from "react"
import axios from "axios"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
// core components
import CustomHeader from "components/Header/CustomHeader.jsx"
import CustomFooter from "components/Footer/CustomFooter.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"
// Gatsby Libraries
import { graphql, useStaticQuery } from "gatsby"
// page sections
import HotPepperEatingFormComplete from "./Sections/HotPepperEatingFormComplete.jsx"
import HotPepperEatingForm from "./Sections/HotPepperEatingForm.jsx"
import SectionTimer from "../LandingPage/Sections/SectionTimer.jsx"

// page styles
import hotPepperEatingRegistrationPageStyle from "assets/jss/material-kit-pro-react/views/hotPepperEatingRegistrationPageStyle.jsx"
const useStyles = makeStyles(hotPepperEatingRegistrationPageStyle)

const registrationFormEndpoint =
  "https://cznh3fqbab.execute-api.us-west-2.amazonaws.com/prod/pepper-contest"

export default function HotPepperEatingRegistrationPage() {
  const { hotPepperEatingRegistrationBackground } = useStaticQuery(graphql`
    query getHotPepperEatingRegistrationBackground {
      hotPepperEatingRegistrationBackground: file(
        relativePath: { eq: "banners/hot-pepper-eating-registration.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phoneNumber: "",
  })
  const [formCompleted, setFormCompleted] = React.useState(false)
  const [formError, setFormError] = React.useState(false)
  const [submitError, setSubmitError] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    window.scrollTo(0, 0)
  }

  const submitFormData = async () => {
    try {
      await axios.post(registrationFormEndpoint, formData)
      if (isMounted.current) {
        handleNext()
      }
    } catch (error) {
      if (isMounted.current) {
        setSubmitError(true)
        setFormError(true)
      }
    }
  }

  const handleSubmit = () => {
    setFormCompleted(true)
  }

  useEffect(() => {
    if (formCompleted) {
      submitFormData()
    }
  }, [formCompleted, formData])

  return (
    <div>
      <SEOHeader
        title="Scoville Showdown Registration - Chilimania"
        description="This is the online registration form for the Chilimania Hot Pepper Eating Contest"
      />
      <CustomHeader />
      <ReactParallax
        imageData={
          hotPepperEatingRegistrationBackground.childImageSharp.gatsbyImageData
        }
        imageTitle={"Hot Pepper Eating Contest Registration Online Banner"}
        filter="dark"
        imageStyle={{ height: "75vh" }}
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12}>
              <h1 className={classes.pageTitle}>
                Chilimania Scoville Showdown Registration
              </h1>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <GridContainer
          justifyContent="center"
          className={classes.stepContainer}
        >
          <GridItem xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step key="details">
                <StepLabel error={formError}>Details</StepLabel>
              </Step>
              <Step key="finish">
                <StepLabel error={submitError}>Finish</StepLabel>
              </Step>
            </Stepper>
            {!formCompleted && (
              <HotPepperEatingForm
                handleNext={handleNext}
                handleSubmit={handleSubmit}
                formData={formData}
                setFormData={setFormData}
                setFormCompleted={setFormCompleted}
                formError={formError}
                setFormError={setFormError}
              />
            )}
            {formCompleted && (
              <HotPepperEatingFormComplete
                handleNext={handleNext}
                submitError={submitError}
              />
            )}
          </GridItem>
        </GridContainer>
      </div>
      <SectionTimer />
      <CustomFooter />
    </div>
  )
}
